import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {NavigationService} from "../navigation.service";

@Component({
    selector: 'app-side-nav-list',
    templateUrl: './side-nav-list.component.html',
    styleUrls: ['./side-nav-list.component.scss']
})
export class SideNavListComponent implements OnInit {
    @Output() sideNavClose = new EventEmitter();

    constructor(public authService: AuthService, public navService: NavigationService) {
    }

    ngOnInit() {

    }

    onClose() {
        this.sideNavClose.emit();
    }

    onLogout() {
        this.authService.logout();
        this.onClose();
    }
}
