import {NgModule} from '@angular/core';
import {WelcomeComponent} from "./welcome.component";
import {SharedModule} from "../shared/shared.module";
import {MatCardModule} from "@angular/material/card";

@NgModule({
    declarations: [
        WelcomeComponent
    ],
    imports: [
        SharedModule,
        MatCardModule
    ]
})
export class WelcomeModule {
}
