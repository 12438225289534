import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from "@angular/flex-layout";
import {ExerciseToTextPipe} from './exercise-to-text.pipe';
import {ScorePipe} from './score.pipe';


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule
    ],
    exports: [
        CommonModule,
        FlexLayoutModule,
        ExerciseToTextPipe,
        ScorePipe
    ],
    declarations: [ExerciseToTextPipe, ScorePipe]
})
export class SharedModule {
}
