import {MovementModel} from "../../movement/movement.model";

export enum ScoreType {
    TIME = 'time',
    REPS = 'reps'
}

export enum WodTypes {
    FOR_TIME = 'FT',
    ROUND_FOR_TIME = 'RFT',
    FOR_QUALITY = 'FQ',
    EMOM = 'EMOM',
    AMRAP = 'AMRAP',
    AMREP = 'AMREP',
    TABATA = 'TBT',
    FIGHT_GONE_BAD = 'FGB',
    DEATH_BY = 'DB',
    FOR_LOAD = 'FL'
}

export const calUnit = ['cal'];
export const weightUnit = ['kg', 'livres', 'poods'];
export const heightUnit = ['m', 'cm', 'inch', 'foot'];
export const distanceUnit = ['m', 'km', 'foot', 'yard', 'miles'];

export class ForTime {
    constructor(
        public time: number, // seconds
        public exercices: string[], // @todo transform string array with object array (to define)
        public comments: string
    ) {
    }
}

export class Training {
    constructor(
        public exercises: Exercise[] = [],
        public user: string = null,
        public type: string = null,
        public typeCode: string = null,
        public rounds: number = null,
        public score: { type: ScoreType, score: number } = null,
        public timeCap: number = null,
        public time: number = null,
        public date: number = null,
        public comments: string = null,
    ) {
    }
}

export class Exercise {
    constructor(
        public movement: MovementModel,
        public condition: ExerciseCondition[],
    ) {
    }
}

export class ExerciseCondition {
    constructor(
        public count: number = null,
        public unit: string = null,
        public label: string = null,
    ) {
    }
}
