import {Action} from "@ngrx/store";
import {Exercise, ScoreType, Training} from "../wod/wod.model";

export enum TrainingAction {
    SET = '[Training] Set',
    FETCH = '[Training] Fetch',
    SAVE = '[Training] Save',
    SET_WOD_TYPE = '[Training] Set wod type',
    SET_TIME_CAP = '[Training] Set time cap',
    SET_ROUNDS = '[Training] Set rounds',
    SET_USER = '[Training] Set user',
    SET_SCORE = '[Training] Set score',
    ERROR = '[Training] Set error'
}

export enum ExerciseAction {
    SET = '[Movement] Set',
    REMOVE = '[Movement] Remove'
}

export class SetTraining implements Action {
    readonly type = TrainingAction.SET;

    constructor(public payload: Training) {
    }
}

export class FetchTraining implements Action {
    readonly type = TrainingAction.FETCH;

    constructor(public payload: string) {
    }
}

export class SetExercise implements Action {
    readonly type = ExerciseAction.SET;

    constructor(public payload: Exercise) {
    }
}

export class RemoveExercise implements Action {
    readonly type = ExerciseAction.REMOVE;

    constructor(public payload: number) {
    }
}

export class SetWodType implements Action {
    readonly type = TrainingAction.SET_WOD_TYPE;

    constructor(public payload: { type: string, typeCode: string }) {
    }
}

export class SetTimeCap implements Action {
    readonly type = TrainingAction.SET_TIME_CAP;

    constructor(public payload: number) {
    }
}

export class SetRounds implements Action {
    readonly type = TrainingAction.SET_ROUNDS;

    constructor(public payload: number) {
    }
}

export class SetScore implements Action {
    readonly type = TrainingAction.SET_SCORE;

    constructor(public payload: { type: ScoreType, score: number }) {
    }
}


export class SaveWod implements Action {
    readonly type = TrainingAction.SAVE;
}

export class WodError implements Action {
    readonly type = TrainingAction.ERROR;
}

export class SetTrainingUser implements Action {
    readonly type = TrainingAction.SET_USER;

    constructor(public payload: string) {
    }
}


export type TrainingActions =
    SetTraining
    | FetchTraining
    | SetExercise
    | RemoveExercise
    | SetWodType
    | SetTimeCap
    | SetScore
    | SaveWod
    | SetTrainingUser
    | SetRounds
    | WodError;
