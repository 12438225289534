import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WelcomeComponent} from './welcome/welcome.component';
import {AuthGuard} from "./auth/auth.guard";


const routes: Routes = [
    {path: '', component: WelcomeComponent},
    {
        path: 'training',
        loadChildren: () => import('./training/training.module').then(m => m.TrainingModule),
        canLoad: [AuthGuard]
    },
    {
        path: 'timers',
        loadChildren: () => import('./timers/timers.module').then(m => m.TimersModule),
        canLoad: [AuthGuard]
    },
    {
        path: 'movements',
        loadChildren: () => import('./movement/movement.module').then(m => m.MovementModule),
        canLoad: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule {
}
