import {Action} from "@ngrx/store";
import {User} from "../user.model";

export enum UserAction {
    SET = '[User] Set',
}


export class SetUser implements Action {
    readonly type = UserAction.SET;

    constructor(public payload: User) {
    }
}


export type AuthActions =
    SetUser;
