import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    menu = [
        {
            id: '',
            class: '',
            icon: 'face',
            link: '/signup',
            label: 'Sign Up',
            isExternal: false,
            authRequired: false,
            title: 'sign up'
        },
        {
            id: '',
            class: '',
            icon: 'input',
            link: '/login',
            label: 'Login',
            isExternal: false,
            authRequired: false,
            title: 'login'
        },
        {
            id: '',
            class: '',
            icon: 'fitness_center',
            link: '/training',
            label: 'Training',
            isExternal: false,
            authRequired: true,
            title: 'training'
        },
        {
            id: '',
            class: '',
            icon: 'timer',
            link: '/timers',
            label: 'Timers',
            isExternal: false,
            authRequired: true,
            title: 'timers'
        },
        {
            id: '',
            class: '',
            icon: 'directions_run',
            link: '/movements',
            label: 'Movements',
            isExternal: false,
            authRequired: true,
            title: 'movements'
        }
    ]

    constructor() {
    }
}
