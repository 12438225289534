import {ExerciseAction, TrainingAction, TrainingActions} from "./training.actions";
import {Training} from "../wod/wod.model";

export interface TrainingState {
    trainings: Training[];
    trainingForm: Training;
}

const initialTrainingState: TrainingState = {
    trainings: null,
    trainingForm: new Training()
};

export function TrainingReducer(state: TrainingState = initialTrainingState, action: TrainingActions) {
    switch (action.type) {
        case TrainingAction.SET:
            return {...state, trainingForm: action.payload};
        case TrainingAction.SET_WOD_TYPE:
            return {
                ...state, trainingForm: {
                    ...state.trainingForm,
                    typeCode: action.payload.typeCode,
                    type: action.payload.type
                }
            };
        case TrainingAction.SET_TIME_CAP:
            return {
                ...state,
                trainingForm: {
                    ...state.trainingForm,
                    timeCap: action.payload
                }
            };
        case TrainingAction.SET_ROUNDS:
            return {
                ...state,
                trainingForm: {
                    ...state.trainingForm,
                    rounds: action.payload
                }
            };
        case TrainingAction.SET_USER:
            return {
                ...state,
                trainingForm: {
                    ...state.trainingForm,
                    user: action.payload,
                    date: new Date().getTime()
                }
            };
        case TrainingAction.SET_SCORE:
            return {
                ...state,
                trainingForm: {
                    ...state.trainingForm,
                    score: action.payload
                }
            };
        case ExerciseAction.SET:
            return {
                ...state,
                trainingForm: {
                    ...state.trainingForm,
                    exercises: [
                        ...state.trainingForm.exercises,
                        action.payload
                    ]
                }
            };
        case ExerciseAction.REMOVE:
            if (action.payload > -1) {
                state.trainingForm.exercises.splice(action.payload, 1);
                const newExercises = [...state.trainingForm.exercises];
                return {
                    ...state,
                    trainingForm: {
                        ...state.trainingForm,
                        exercises: newExercises
                    }
                }
            } else {
                return {...state};
            }
        default:
            return {...state};
    }
}


