import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {MatNativeDateModule} from "@angular/material/core";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {FormsModule} from '@angular/forms';
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';

import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {EntityDataModule} from '@ngrx/data';
import {entityConfig} from './entity-metadata';
import {EffectsModule} from '@ngrx/effects';


import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderComponent} from './navigation/header/header.component';
import {SideNavListComponent} from './navigation/side-nav-list/side-nav-list.component';
import {environment} from '../environments/environment';
import {AuthService} from "./auth/auth.service";
import {AuthGuard} from "./auth/auth.guard";
import {reducers} from './app.reducer';
import {AppEffects} from './app.effects';
import {UiService} from "./shared/ui.service";
import {AuthModule} from "./auth/auth.module";
import {WelcomeModule} from "./welcome/welcome.module";
import {MatButtonModule} from "@angular/material/button";
import {SharedModule} from "./shared/shared.module";
import {HttpClientModule} from "@angular/common/http";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {InputDialogComponent} from "./timers/input-dialog/input-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SideNavListComponent,
        InputDialogComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        MatNativeDateModule,
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        MatSnackBarModule,
        MatSelectModule,
        // StoreModule.forRoot(reducers, {
        //     metaReducers,
        //     runtimeChecks: {strictStateImmutability: true, strictActionImmutability: true}
        // }),
        EffectsModule,
        StoreRouterConnectingModule.forRoot(),
        EntityDataModule.forRoot(entityConfig),
        EffectsModule.forRoot([AppEffects]),
        WelcomeModule,
        AuthModule,
        StoreModule.forRoot(reducers),
        environment.production ? [] : StoreDevtoolsModule.instrument(),
        MatDialogModule
    ],
    providers: [AuthService, AuthGuard, UiService],
    entryComponents: [
        InputDialogComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
