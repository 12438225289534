import {EventEmitter, Injectable} from '@angular/core';
import {Exercise} from "./wod.model";
import {Store} from "@ngrx/store";
import {TrainingState} from "../store/training.reducer";
import {SetRounds, SetTimeCap, SetTrainingUser} from "../store/training.actions";
import {AuthService} from "../../auth/auth.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class WodService {
    wod: EventEmitter<any> = new EventEmitter<any>();

    constructor(private store: Store<TrainingState>, private authService: AuthService, private db: AngularFirestore) {
    }

    setWodValues(wod) {
        if (wod.timeCap) {
            this.store.dispatch(new SetTimeCap(wod.timeCap));
        }
        if (wod.rounds) {
            this.store.dispatch(new SetRounds(wod.rounds));
        }
        this.store.dispatch(new SetTrainingUser(this.authService.getUser().id))
    }

    exerciseToString(exerciseForm) {
        let exercise = '';
        if (exerciseForm.movement) {
            if (exerciseForm.calorie && exerciseForm.calorieUnit) {
                exercise += `${exerciseForm.calorie} ${exerciseForm.calorieUnit} `;
            }
            if (exerciseForm.distance && exerciseForm.distanceUnit) {
                exercise += `${exerciseForm.distance} ${exerciseForm.distanceUnit} `
            }
            if (exerciseForm.reps) {
                exercise += `${exerciseForm.reps} `
            }

            exercise += exerciseForm.movement + ' ';

            if (exerciseForm.height && exerciseForm.heightUnit) {
                exercise += `@ ${exerciseForm.height} ${exerciseForm.heightUnit} `
            }
            if (exerciseForm.weight && exerciseForm.weightUnit) {
                exercise += `@ ${exerciseForm.weight} ${exerciseForm.weightUnit} `
            }
            if (exerciseForm.time) {
                exercise += `for ${exerciseForm.time} ${exerciseForm.timeUnit} `
            }
        }
        return exercise;
    }

    addExerciseCondition(exerciseForm) {
        const conditions = [];
        const units = ['reps', 'calorie', 'distance', 'weight', 'height', 'time'];
        units.forEach((unit: string) => {
            if (exerciseForm[unit] && exerciseForm[unit + 'Unit']) {
                conditions.push({
                    unit: exerciseForm[unit + 'Unit'],
                    count: exerciseForm[unit],
                    label: unit
                });
            }
        });
        return conditions;
    }

    formatExercise(exerciseForm): Exercise {
        let formattedExercise: Exercise = null;

        if (exerciseForm.movement) {
            formattedExercise = {
                movement: {
                    label: exerciseForm.movement,
                    modality: exerciseForm.movementModality
                },
                condition: this.addExerciseCondition(exerciseForm)
            };
        }
        return formattedExercise;
    }

    prepareExerciseResults(exercise: Exercise): string {
        const formattedExercise = {};
        exercise.condition.map(condition => {
            const unitLabel = `${condition.label}Unit`;
            formattedExercise[condition.label] = condition.count;
            formattedExercise[unitLabel] = condition.unit;
        });
        formattedExercise['movement'] = exercise.movement.label;

        return this.exerciseToString(formattedExercise);
    }

    prepareTrainingResults(trainings) {
        if (trainings) {
            return trainings.map(training => {
                const formattedExercise = training.exercises.map(exercise => {
                    return this.prepareExerciseResults(exercise)
                });
                training.exercises = formattedExercise;
                return training;
            });

        }
        return {};
    }

    fetchLastTraining() {
        if (this.authService.getUser().id) {
            const query = ref => ref
                .where(
                    'user',
                    '==',
                    this.authService.getUser().id)
                .orderBy("date", "desc")
                .limit(1);

            return this.db
                .collection('training', query)
                .valueChanges()
        }
        return null;
    }

    fetchTrainingByDate(startDate: number, endDate: number) {
        if (this.authService.getUser().id) {
            const query = ref => ref
                .where(
                    'user',
                    '==',
                    this.authService.getUser().id)
                .where(
                    'date',
                    '>=',
                    startDate
                )
                .where(
                    'date',
                    '<=',
                    endDate
                )
                .orderBy("date", "desc")

            return this.db
                .collection('training', query)
                .valueChanges()
                .pipe(
                    map(training => {
                        return training;
                    })
                )
        }
        return null;
    }
}
