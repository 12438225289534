import {Action} from "@ngrx/store";

export enum Movement {
    SET = '[Movement] Set'
}

export enum Movements {
    SET = '[Movements] Set',
    FETCH = '[Movements] Fetch'
}

export class SetMovement implements Action {
    readonly type = Movement.SET;

    constructor(public payload: string) {
    }
}

export class SetMovements implements Action {
    readonly type = Movements.SET;

    constructor(public payload: string[]) {
    }
}

export class FetchMovements implements Action {
    readonly type = Movements.FETCH;

    constructor(public payload: string) {
    }
}

export type MovementActions = SetMovement | FetchMovements | SetMovements;
