import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../auth/auth.service";
import {WodService} from "../training/wod/wod.service";
import {Subscription} from "rxjs";
import {ScoreType, Training} from "../training/wod/wod.model";

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {
    public lastTraining: Training[];
    private fetchTrainingSub: Subscription;
    public scoreType = ScoreType;

    constructor(private authService: AuthService, private wodService: WodService) {
    }

    ngOnInit() {
        const [startDate, endDate] = this.getCurrentMonthDateRange();
        this.fetchTrainingSub = this.wodService.fetchLastTraining().subscribe((training: Training[]) => {
            this.lastTraining = training;
        });

        this.wodService
            .fetchTrainingByDate(
                startDate.getTime(),
                endDate.getTime()
            )
            .subscribe(console.log);
    }

    getCurrentMonthDateRange() {
        const date = new Date(), year = date.getFullYear(), month = date.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);

        return [firstDay, lastDay];
    }

    ngOnDestroy() {
        if (this.fetchTrainingSub) {
            this.fetchTrainingSub.unsubscribe();
        }
    }
}
