import * as fromUi from './shared/ui.reducer';
import * as fromMovement from './movement/store/movement.reducer';
import * as fromTraining from './training/store/training.reducer';
import {ActionReducerMap, createFeatureSelector, createSelector} from "@ngrx/store";
import {AuthReducer, AuthState} from "./auth/store/auth.reducer";


export interface State {
    auth: AuthState;
    ui: fromUi.State;
    movement: fromMovement.MovementState,
    training: fromTraining.TrainingState
}

export const reducers: ActionReducerMap<State> = {
    auth: AuthReducer,
    ui: fromUi.uiReducer,
    movement: fromMovement.MovementReducer,
    training: fromTraining.TrainingReducer
};

export const getUiState = createFeatureSelector<fromUi.State>('ui');
export const getIsLoading = createSelector(getUiState, fromUi.getIsLoading);
