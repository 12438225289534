import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {Observable} from "rxjs";
import {NavigationService} from "../navigation.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Output() sideNavToggle = new EventEmitter();
    isAuth: Observable<boolean>;

    constructor(private authService: AuthService, public navService: NavigationService) {
    }

    ngOnInit() {
        this.isAuth = this.authService.authChange;
    }

    onLogout() {
        this.authService.logout();
    }

    onToggleSideNav() {
        this.sideNavToggle.emit();
    }
}
