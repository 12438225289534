import {Pipe, PipeTransform} from '@angular/core';
import {Exercise} from "../training/wod/wod.model";
import {WodService} from "../training/wod/wod.service";

@Pipe({
    name: 'exerciseToText'
})
export class ExerciseToTextPipe implements PipeTransform {

    constructor(private wodService: WodService) {
    }

    transform(exercise: Exercise): string {
        return this.wodService.prepareExerciseResults(exercise);
    }

}
