import {Router} from "@angular/router";

import {Subject} from "rxjs";

import {AuthData} from "./auth-data.model";
import {User} from "./user.model";
import {Injectable} from "@angular/core";
import {AngularFireAuth} from "@angular/fire/auth";
import {UiService} from "../shared/ui.service";
import {Store} from "@ngrx/store";
import {State} from "../app.reducer";
import {StartLoading, StopLoading} from "../shared/ui.actions";
import {SetUser} from "./store/auth.actions";


@Injectable()
export class AuthService {
    authChange = new Subject<boolean>();
    private user: User;
    private isAuthenticated: boolean;

    constructor(private router: Router, private afAuth: AngularFireAuth, private uiService: UiService, private store: Store<State>) {
    }

    initAuthListener() {
        this.afAuth.authState.subscribe(user => {

            if (user) {
                this.isAuthenticated = true;
                this.setUser({email: user.email, id: user.uid});
                this.authChange.next(true);
                this.router.navigate(['/training']);
            } else {
                this.authChange.next(false);
                this.router.navigate(['/login']);
                this.isAuthenticated = false;
            }
        })
    }


    registerUser(authData: AuthData) {
        // this.uiService.loadingStateChanged.next(true);
        this.store.dispatch(new StartLoading());
        this.afAuth.auth.createUserWithEmailAndPassword(authData.email, authData.password)
            .then(() => {
                // this.uiService.loadingStateChanged.next(false);
                this.store.dispatch(new StopLoading());
            })
            .catch(error => {
                // this.uiService.loadingStateChanged.next(false);
                this.store.dispatch(new StopLoading());
                this.uiService.showSnackBar(error.message, null, 3000);
            });
    }

    login(authData: AuthData) {
        // this.uiService.loadingStateChanged.next(true);
        this.store.dispatch({type: 'START_LOADING'});
        this.afAuth.auth.signInWithEmailAndPassword(authData.email, authData.password)
            .then(result => {
                console.log(result);
                // this.uiService.loadingStateChanged.next(false);
                this.store.dispatch({type: 'STOP_LOADING'});
            })
            .catch(error => {
                // this.uiService.loadingStateChanged.next(false);
                this.store.dispatch({type: 'STOP_LOADING'});
                this.uiService.showSnackBar(error.message, null, 3000);
            });
    }

    logout() {
        this.afAuth.auth.signOut();
    }

    setUser(user) {
        this.user = user;
        this.store.dispatch(new SetUser(user));
    }

    getUser() {
        return {...this.user};
    }

    isAuth() {
        return this.isAuthenticated;
    }
}
