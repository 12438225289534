// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: 'AIzaSyAaL8k5zmr_rfL9b7kc1Cw_UYxjbjUpvsg',
        authDomain: 'crossfit-a14b3.firebaseapp.com',
        databaseURL: 'https://crossfit-a14b3.firebaseio.com',
        projectId: 'crossfit-a14b3',
        storageBucket: 'crossfit-a14b3.appspot.com',
        messagingSenderId: '930810678440',
        appId: '1:930810678440:web:b897ef0b7ab7d7e94b869d',
        measurementId: 'G-519Z132TDV'
    },
    algolia: {
        appId: '38149TZQUJ',
        apiKey: '3d78f3b3860a0070b795842779fffe24',
        index: 'dev_Movement'
    },
    movement: {
        url: 'https://www.beyondthewhiteboard.com/exercises/autocomplete_name.json'
    },
    youtube: {
        url: 'https://www.googleapis.com/youtube/v3/search'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
