import {Movement, MovementActions, Movements} from "./movement.actions";

export interface MovementState {
    movement: string;
    movements: string[];
}

const initialMovementState: MovementState = {
    movement: null,
    movements: null
};

export function MovementReducer(state: MovementState = initialMovementState, action: MovementActions) {
    switch (action.type) {
        case Movement.SET:
            return {...state, movement: action.payload};
        case Movements.SET:
            return {...state, movements: action.payload};
        default:
            return {...state};
    }

}

export const getMovements = (state: MovementState) => (state && state.movements) ? state.movements : null;

