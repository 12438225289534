import {EntityDataModuleConfig, EntityMetadataMap} from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {Movement: {}};

const pluralNames = {};

export const entityConfig: EntityDataModuleConfig = {
    entityMetadata,
    pluralNames
};
