import {User} from "../user.model";
import {AuthActions, UserAction} from "./auth.actions";

export interface AuthState {
    user: User;
}

const initialAuthState: AuthState = {
    user: null,
};

export function AuthReducer(state: AuthState = initialAuthState, action: AuthActions) {
    switch (action.type) {
        case UserAction.SET:
            return {
                ...state, user: action.payload
            };
        default:
            return {...state};
    }
}


