import {Pipe, PipeTransform} from '@angular/core';
import {ScoreType} from "../training/wod/wod.model";

@Pipe({
    name: 'score'
})
export class ScorePipe implements PipeTransform {
    scoreType = ScoreType;

    transform(score: { type: string, score: number }): string {
        if (score) {
            switch (score.type) {
                case this.scoreType.REPS:
                    return score.score + ' reps';
                case this.scoreType.TIME:
                    let result = score.score / 60 !== 0 ? Math.floor(score.score / 60) + ' min' : '';
                    console.log(result);
                    if (score.score % 60 !== 0) {
                        const seconds = score.score % 60 + ' s'
                        if (result === '') {
                            result += seconds;
                        } else {
                            result += ' and ' + seconds;
                        }

                    }
                    return result;
            }
        }
        return null;
    }

}
